import React from 'react'
import LoaderTri from '../Loader/LoaderTri'

export const FallbackLoading = () => {
    return (
        <div
            className=" d-flex justify-content-center align-items-center pt-5 flex-column"
            style={{ height: window.screen.height * 0.20 }}>
            <div className="d-flex justify-content-center" >

                <LoaderTri />

            </div>
            <span className="sr-only">
                <p><i>Loading...</i></p>
            </span>
        </div>
    )
}


