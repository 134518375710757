import React from 'react'
import { Triangle, } from 'react-loader-spinner';

const LoaderTri = (props) => {
    return (
        <div className='  d-flex justify-content-center align-items-center'>

            <Triangle
                {...props}
                height={props.height ? props.height : "80"}
                width={props.width ? props.width : "80"}

                color="#7286a0"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default LoaderTri;