import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Modal } from 'antd';
import Typeahead from './TypeAhead';
import ApiHandler from '../../../ApiHandler';
import GlobalVariable from '../../../GlobalVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';


const columns = [
    {
        header: "Product Name",
        field: "prodName",
        cols: 4,
    },
    {
        header: "Product Code",
        field: "prodCode",
        cols: 2,
    },
    {
        header: "SRate",
        field: "rate",
        cols: 2,
    },
    {
        header: "MRP",
        field: "mrp",
        cols: 2,
    },

    {
        header: "GST",
        field: "gst",
        cols: 2,
    },

];
export const SearchProductTd = (propsfull) => {
    const [isEditing, setIsEditing] = useState(false);
    const productRef = useRef();

    const { setVal, ...props } = propsfull;
    // console.log({ propsfull });
    useEffect(() => {
        // console.log({ isEditing });
        if (isEditing) {
            setTimeout(() => {
                isEditing && productRef.current && productRef.current.focus();
            },);
        }

        return () => {
            console.log("----- Unmounting -----");
            setProductSearch(v => {
                return { ...v, options: [] }
            })
        }
    }, [isEditing])

    const [productSearch, setProductSearch] = useState({
        options: [],
        selected: [],

    })

    const searchHandler = (val) => {
        const user = JSON.parse(localStorage.getItem("user-info"));
        const requestData = {
            wClientid: GlobalVariable.wcClient.toString(),
            search: val,
            areaCode: user.areaCode + ""

        };
        ApiHandler.post({
            url: "/api/Search/ProductByName",
            data: requestData
        }).then(res => {
            const temp = [];
            for (let i = 0; i < 10; i++) {
                if (res.data[i]) {
                    const element = res.data[i];
                    temp.push(element)
                }
            }
            setProductSearch(v => {
                return { ...v, options: temp }
            })
        })
    }


    return (
        <td
            style={{ maxWidth: 150 }}
        >
            <div
                className={`d-flex  ${props.children ? "justify-content-between" : "justify-content-end"}`}
            >

                {props.children}


                <FontAwesomeIcon
                    icon={faSearch}
                    role='button'
                    onClick={e => {
                        setIsEditing(!isEditing)
                    }}
                />

            </div>
            <Modal
                closeIcon={<FontAwesomeIcon icon={faClose} className='mb-4' />}
                open={isEditing}
                onOk={() => {
                    console.log("Ok Clicked ", productSearch)
                    setIsEditing(false);
                    productSearch.selected.length > 0 && setVal(productSearch.selected[0], props.index);
                }}
                onCancel={() => {
                    setIsEditing(false);
                }}
            >

                <br />

                <Typeahead
                    ref={productRef}
                    placeholder="Search Product"
                    id="searchProduct"
                    labelKey="prodName"
                    name="prodName"
                    onKeyDown={e => {
                        console.log({ e });
                        // key==="Enter"
                        if (e.keyCode === 13 && productSearch.selected.length > 0) {
                            setTimeout(() => {
                                productSearch.selected.length > 0 && setVal(productSearch.selected[0], props.index);
                                setIsEditing(false);
                            },);
                        }
                    }}
                    columns={columns}
                    // colWidth={window.screen.width}
                    isLoading={false}
                    //when typing in the text box search in handled here
                    onSearch={searchHandler}
                    onChange={e => {
                        console.log(e[0]);
                        setProductSearch(v => { return { ...v, selected: e } })
                    }}
                    options={productSearch.options ? productSearch.options : []}
                    selected={productSearch.selected ? productSearch.selected : null}

                />
            </Modal>
        </td >
    )
}
