import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import GlobalVariable from "./GlobalVariable";
import ApiHandler from "./ApiHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LoaderTri from "./components/Loader/LoaderTri";

export default function Cproduct({ comCode, setComCode }) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const cancelToken = ApiHandler.cancelToken;
    const source = cancelToken.source();
    setIsLoading(true);

    ApiHandler.post({
      //url: localUrl + "api/OwnApp/GetComOrderList",
      url: "api/POPSReports/productbycompanycode/",
      data: {
        wClientid: GlobalVariable.wcClient + "",
        search: comCode + "",
      },
      cancelToken: source.token,
    })
      .then((result) => {
        console.log(result);
        console.log(result.data);
        setData([...result.data]);
        // console.log(result.data.orderdate)
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      source.cancel();
    };
  }, [comCode]);

  // useEffect(() => {

  //     console.log(comCode);
  //     if (comCode) {

  //         axios.post(GlobalVariable.ReliableSoftURL + "/api/POPSReports/productbycompanycode/", {
  //             wClientid: GlobalVariable.wcClient + "",
  //             search: comCode + ""
  //         }).then(res => {
  //             console.log(res.data);
  //             setData([...res.data])
  //         }).catch(err => {
  //             console.error(err);
  //         })

  //     }
  // }, [comCode])

  useEffect(() => {
    console.log(data);
    return () => {};
  }, [data]);

  return (
    <>
      <Button
        variant="primary"
        style={{
          float: "left",
        }}
        className="m-2 mt-4"
        onClick={() => {
          setComCode();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
        Back
      </Button>

      <div className="px-2">
        <div
          className="grids "
          style={{
            width: "100%",
            overflowX: "scroll",
          }}
        >
          <Table striped>
            <thead style={{ backgroundColor: "#3ca0e7", color: "white" }}>
              <tr style={{ border: "1px solid black" }}>
                <th style={{ border: "1px solid black", minWidth: "200px" }}>
                  Product Name
                </th>
                <th style={{ border: "1px solid black", minWidth: "160px" }}>
                  Packing
                </th>
                <th style={{ border: "1px solid black", minWidth: "155px" }}>
                  Scheme
                </th>
                <th style={{ border: "1px solid black", minWidth: "140px" }}>
                  MRP
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((elm, i) => {
                return (
                  <tr key={i}>
                    <td>{elm.prodName}</td>
                    <td> {elm.case1}</td>
                    <td>{elm.scheme}</td>
                    <td>{elm.mrp}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {isLoading && <LoaderTri />}
      </div>
    </>
  );
}
