
const TransferData =
{
    billDate: "Bill Date",
    billNo: "Bill No",
    amount: "Amount",
    pending: "Pending",
    runningTotal: "Running Total",
    duedate: "Due Date",
    date: "Date",
    particulars: "Particulars",
    debit: "Debit",
    credit: "Credit",
    balance: "Balance",
    product: "Product",
    pack: "Pack",
    qty: "Qty",
    status: "Status",
    prodName: "Product Name",
    case1: "Case 1",
    scheme: "Scheme",
    rate: "Rate",
    mrp: "MRP",
    gst: "GST",
    companyName: "Company Name",
    genName: "Generic Name",
    customerName: "Customer",
    productName: "Product",
    free: "Free",
    value: "Value",
    orderNo: "Order No",
    orderDate: "Order Date",
    remarks: "Remarks",
    challanNo: "Challan No",
    challanDate: "Challan Date",
    lrNo: "LR No",
    lrDate: "LR Date",
    trpValue: "TRP Value",
    mrpValue: "MRP Value",
    supplier: "Supplier",
    flag: "Flag",
    product_Name: "Product Name",
    prevLastSale: "Previous Last Sale",
    prevSale: "Previous Sale",
    opening: "Opening",
    receipt: "Receipt",
    sales: "Sales",
    salesRtn: "Sales RTN",
    saleValue: "Sale Value",
    purRtn: "Purchase RTN",
    others: "Others",
    closing: "Closing",
    cl_Value: "Cl Value",
    notes: "Notes",
    name: "Name",
    place: "Place",
    taxable: "Taxable",
    days: "Days",
    bDisAmt: "B. Disc. Amt.",
}

export default TransferData;

