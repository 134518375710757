import React from 'react'
import '../../Styles/Customer/customerFooter.scss'

function CustomFooter() {
  return (
    <footer className="customerFooter">
      <p>
        By
        <a
          href="http://www.relysoft.in/"
          target="_blank"
          rel="noreferrer"
          className="d-inline-block mx-1"
        >
          Reliable Software, Goa.
        </a>
        <span>
          <b>Ph:</b> <a className='text-white' href="tel:07969668000">07969668000</a>
        </span>
      </p>
    </footer>
  );
}

export default CustomFooter;