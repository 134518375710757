import { components } from "react-select";
import './Customer.css'

function CustomerFooter() {

    return (
        <>
            <div className="mb-0  w-100 text-center"
                style={{
                    backgroundColor: "#66cccc",
                    padding: "5px",
                    position: "fixed",
                    bottom: 0,
                    zIndex: 999
                }}>
                <p className="m-0">By Reliable Software, Goa. <span className="white">Ph: 7620789789</span> </p>
            </div>
        </>
    )
}

export default CustomerFooter;