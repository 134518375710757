import React, { useRef, useState, } from 'react';
import HeaderCustomer from '../../snippets/Customer/HeaderCustomer';
import Footer from '../../snippets/Footer';
import GlobalVariable from '../../GlobalVariable';
import { Modal, Table } from 'react-bootstrap';
import ApiHandler from '../../ApiHandler';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { SearchProductTd } from './components/Search';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Popconfirm } from 'antd';
import { Importer, ImporterField } from 'react-csv-importer';



// const styles = {
//     csvReader: {
//         display: 'flex',
//         flexDirection: 'row',
//         marginBottom: 10,
//     },
//     browseFile: {
//         width: '20%',
//         border: '1px solid #ccc',

//     },
//     acceptedFile: {
//         border: '1px solid #ccc',
//         height: 35,
//         lineHeight: 2,
//         paddingLeft: 10,
//         width: '80%',
//     },
//     remove: {
//         borderRadius: 0,
//         padding: '0 20px',
//         border: '1px solid #ccc',

//     },
//     progressBarBackgroundColor: {
//         backgroundColor: 'red',
//     },
// };

const initialOptions = {
    productIndex: "",
    packingIndex: "",
    qtyIndex: "",
    headersRowIndex: "",
};

// const InputContainer = (props) => <div className='mb-3'>{props.children}</div>

//const allowedExtensions = ["csv"];


const initialRemarksOptions = {
    displayName: "Select Remarks",
    pickupOrder: "Pickup Order",
    deliveryOrder: "Delivery Order",
    customValue: "",
}
const OrderUpload = () => {
    // const { CSVReader } = useCSVReader();
    //const uploadFileRef = useRef();
    const [tempUniqueId, setTempUniqueId] = useState("");

    const [values, setValues] = useState(initialOptions);
    const [headerRow, setHeaderRow] = useState([]);
    const [CsvRawData, setCsvRawData] = useState([]);
    const [uploadData, setUploadData] = useState([])

    const [error, setError] = useState([]);

    const [finalData, setFinalData] = useState([]);

    const [totalVal, setTotalVal] = useState("0.00")
    // console.log({ headerRow, CsvRawData });
    const [urgent, setUrgent] = useState(0);

    const [remarksDropdown, setRemarksDropdown] = useState(initialRemarksOptions);

    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [tableLoading, setTableLoading] = useState(false)
    const getMappingDataHandler = (list) => {
        setTableLoading(true);
        const user = JSON.parse(localStorage.getItem("user-info"));
        let nameNotThere = false
        const requestData = list.map((data, i) => {
            if (!data.rProdName) {
                nameNotThere = true
            }
            return {
                wClientid: GlobalVariable.wcClient.toString(),
                wProdCode: "",
                wProdPack: "",
                rProdName: data.rProdName,
                custCode: user.code,
                WProdName: "",
                qty: data.qty
            }
        });

        if (nameNotThere) {
            setTableLoading(false);
            toast.error("Retail Name Could Not be found")
            return;
        }
        // console.log({ user, requestData });
        // return;
        ApiHandler.post({
            url: "api/Orders/GetMappingData/",
            data: requestData,
            cancelToken: cancelTokenRef.current.token
        })
            .then(res => {
                console.log(res.data);

                setFinalData(res.data.map((data, i) => {
                    return {
                        ...data,

                    }
                }));
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setTableLoading(false);
            })
    }


    const postMappingDataHandler = () => {
        const user = JSON.parse(localStorage.getItem("user-info"));
        setTableLoading(true);


        console.log({ user, finalData });
        // return;
        ApiHandler.post({
            url: "api/Orders/WriteMappingData/",
            data: finalData,
            cancelToken: cancelTokenRef.current.token
        })
            .then(res => {
                console.log(res);
                //  setFinalData(res.data);
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                setTableLoading(false);
            })
    }

    useEffect(() => {
        let isComponentMounted = true;
        if (isComponentMounted) {
            let total = 0;
            finalData.forEach(element => {
                if (element.rate) {
                    const amount = element.qty * element.rate;
                    const percentAmount = (amount * element.gst / 100);
                    const gstPlusAmount = amount + percentAmount
                    // console.log({ gstPlusAmount, amount, percentAmount });
                    total += gstPlusAmount;
                }
            });
            setTotalVal(total.toFixed(2));
        }
        return () => {
            isComponentMounted = false;
        }
    }, [finalData])




    const setUploadDataHandler = useCallback((arr) => {
        const data = arr.reduce((acc, current, i) => {
            // console.log({ acc, current, i });
            if (i === 0) {
                return acc;
            }
            acc.push({
                name: current[values.productIndex],
                qty: current[values.qtyIndex]
            });
            return acc
        }, [])
        setUploadData(data)
    }, [values.productIndex, values.qtyIndex])

    const cancelTokenRef = useRef();

    useEffect(() => {
        const cancelToken = ApiHandler.cancelToken;
        cancelTokenRef.current = cancelToken.source();

        return () => {
            cancelTokenRef.current.cancel();
        }
    }, [])


    useEffect(() => {
        let isComponentMounted = true;
        isComponentMounted && setUploadDataHandler(CsvRawData);
        return () => {
            isComponentMounted = false;
        }
    }, [CsvRawData, headerRow, setUploadDataHandler])


    const setHeaderRowHandler = (data = null) => {
        const csvData = data ? data : CsvRawData;
        csvData.length > 0 && csvData[parseInt(values.headersRowIndex) - 1] && setHeaderRow(csvData[values.headersRowIndex ? parseInt(values.headersRowIndex) - 1 : 0])
    }


    const clearFull = () => {
        setValues(initialOptions);
        setHeaderRow([]);
        setCsvRawData([]);
        setUploadData([]);
        setError([]);
        setFinalData([]);
        setUrgent(0);
        setRemarksDropdown(initialRemarksOptions);
        setTempUniqueId("");
    }

    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

    const [orderNo, setOrderNo] = useState(null);
    const writePopsOrderHandler = async () => {
        if (disableSubmitBtn) {
            return;
        }
        // setDisableSubmitBtn(true)
        const user = JSON.parse(localStorage.getItem("user-info"));
        console.log({ user, finalData });
        const requestFinalData = finalData.filter(c => c.wProdCode)
            .map(data => {
                return {
                    ...data,
                    qty: data.qty ? parseInt(!isNaN(data.qty) ? data.qty : 0) : 0
                }
            })
        if (requestFinalData.length !== finalData.length) {
            toast.error("All Products Should be Mapped");
            return;
        }
        if (requestFinalData.length === 0) {
            setDisableSubmitBtn(false);
            if (finalData.length === 0) {
                toast.error("Products Not Found");

            } else {
                toast.error("Products Not Mapped");
            }
            return;
        }

        // return;
        ApiHandler.post({
            url: "api/Orders/WriteMappingData/",
            data: requestFinalData,
            cancelToken: cancelTokenRef.current.token
        })
            .then(res => {
                console.log(res);
                //  setFinalData(res.data);

                const mapFinal = requestFinalData.map((data, i) => {
                    return {
                        wProdCode: data.wProdCode,
                        qty: data.qty ? data.qty : 0,
                        free: 0,
                        prodname: data.wProdName,
                        entryTime: new Date(),
                        rProdCode: "",
                        pack: data.wProdPack,
                        wBarcode: "",
                        sourceId: i + 1,
                        rate: data.rate ? data.rate : 0
                    }
                });
                let uniqueId = "" + GlobalVariable.wcClient + "_" + user.code + "_" + moment().format("DDMMYYYYHHmmss");
                if (tempUniqueId.trim() !== "") {
                    uniqueId = tempUniqueId;
                }
                setTempUniqueId(uniqueId);
                const requestData = {
                    wclientid: GlobalVariable.wcClient.toString(),
                    custcode: user.code,
                    repcode: 0,
                    uniqueOrderID: uniqueId,
                    orderType: "",
                    rclientid: "",
                    urgent: urgent,
                    remarks: remarksDropdown.displayName,
                    mobNo: "",
                    orderItems: mapFinal.filter(c => (c.wProdCode + "").trim() !== '')
                };

                console.log({ requestData });

                // setTimeout(() => {
                // setDisableSubmitBtn(false);//Remove when return comment
                // }, 3000);
                // return;
                ApiHandler.post({
                    url: "api/Orders/WritePopsOrders",
                    data: requestData,
                    cancelToken: cancelTokenRef.current.token
                })
                    .then(res => {
                        // toast.success("Successfully Saved with order number " + res.data.orderNo)
                        console.log(res.data);
                        setOrderNo(res.data.orderNo)
                        clearFull();
                        setShowSuccessModal(true)
                    })
                    .catch(err => {
                        toast.error("Write props order Failed")
                    })
                    .finally(() => {
                        // setTempUniqueId("");
                        console.log("------Finally For Pops Order");
                        setDisableSubmitBtn(false);
                    })

            }).catch(err => {
                console.error(err);
                toast.error("Could Not Map Data")
            }).finally(() => {
                setDisableSubmitBtn(false);
                console.log("-------Finally For Write");
                // setTempUniqueId("");
                setTableLoading(false);
            })


    }



    const removeWholesaleProduct = (data) => {
        console.log("Removing = ", data);

        ApiHandler.post({
            url: "api/Orders/DeleteMappingData",
            data: [data],

        })
            .then(res => {
                console.log(res);
                setFinalData(v => {
                    let i = v.findIndex(c => c.rProdName === data.rProdName);
                    v[i].wProdName = "";
                    v[i].wProdCode = "";
                    v[i].rate = "";
                    v[i].gst = "";
                    v[i].mrp = "";
                    return [...v]
                })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {

            })
    }

    return (
        <div>
            <HeaderCustomer>
            </HeaderCustomer>


            <div className=' row m-0 pt-2'>
                <div className=' col-lg-10 offset-lg-1'>

                    {error.length > 0 && <div className="alert alert-danger">
                        {error.map((data, i) => {
                            return <li key={i}> {data.message}</li>
                        })}
                    </div>}

                    <h4 className='text-center'> Order Upload</h4>
                    <h6
                        htmlFor="uploadFileId"
                    >Select File to Upload (CSV) </h6>

                    <Importer
                        delimiter={"\n"}

                        dataHandler={async (rows, { startIndex }) => {
                            // required, may be called several times
                            // receives a list of parsed objects based on defined fields and user column mapping;
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            console.log({ rows, startIndex });


                            let tempFinal = finalData;

                            const res = rows.map(data => {
                                return {
                                    rProdName: data.name,
                                    qty: data.qty ? parseInt(!isNaN(data.qty) ? data.qty : 0) : 0
                                }
                            });
                            res.forEach(element => {
                                const index = tempFinal.findIndex(c => (c.rProdName + "").trim().toLocaleLowerCase() === (element.rProdName + "").trim().toLocaleLowerCase());
                                if (index === -1) {
                                    tempFinal.push(element);
                                }

                            });

                            getMappingDataHandler(tempFinal);
                        }}

                        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                        restartable={true} // optional, lets user choose to upload another file when import is complete
                        onStart={({ file, preview, fields, columnFields }) => {
                            // optional, invoked when user has mapped columns and started import
                            console.log({ file, preview, fields, columnFields });
                            // prepMyAppForIncomingData();

                        }}
                        onComplete={({ file, preview, fields, columnFields }) => {
                            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                            // showMyAppToastNotification();

                            console.log({ file, preview, fields, columnFields });
                        }}
                        skipEmptyLines={true}
                        onClose={false}

                    // onClose={({ file, preview, fields, columnFields }) => {
                    //     console.log({ file, preview, fields, columnFields });
                    //     // optional, if this is specified the user will see a "Finish" button after import is done,
                    //     // which will call this when clicked
                    //     // goToMyAppNextPage();

                    // }}

                    // CSV options passed directly to PapaParse if specified:
                    // delimiter={...}
                    // newline={...}
                    // quoteChar={...}
                    // escapeChar={...}
                    // comments={...}
                    // skipEmptyLines={...}
                    // delimitersToGuess={...}
                    // chunkSize={...} // defaults to 10000
                    // encoding={...} // defaults to utf-8, see FileReader API

                    >
                        <ImporterField name="name" label="Name" />
                        <ImporterField name={"qty"} label="Qty" />
                    </Importer>




                    <div style={{
                        width: "100%",
                        overflowX: "scroll"
                    }}>
                        <Table
                            className='mt-2   '
                            striped
                            bordered
                            style={{ border: "1px solid #ccc", marginBottom: 50 }}
                        >
                            <thead>
                                <tr>
                                    <th className='text-end' style={{ maxWidth: 40, minWidth: 30 }}>#</th>
                                    <th>Retail Product Name</th>
                                    <th style={{ maxWidth: 250, minWidth: 190 }}> Wholesale Product Name</th>
                                    <th className='text-end' style={{ maxWidth: 140, minWidth: 80 }}>Qty</th>
                                    <th className='text-end' style={{ maxWidth: 140, minWidth: 80 }}>Rate</th>
                                    <th className='text-end' style={{ maxWidth: 140, minWidth: 80 }}>MRP</th>
                                    <th className='text-end' style={{ maxWidth: 140, minWidth: 80 }}>Gst</th>
                                    <th style={{ maxWidth: 150, minWidth: 90 }} ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {finalData && Array.isArray(finalData) &&

                                    (finalData.length > 0 ? finalData.map((data, i) => {
                                        // console.log(data);
                                        return <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td
                                                style={{ maxWidth: 150 }}
                                            >{data.rProdName}</td>
                                            <SearchProductTd
                                                setVal={val => {
                                                    console.log({ val });
                                                    setFinalData(v => {
                                                        v[i].wProdName = val.prodName;
                                                        v[i].wProdCode = val.prodCode;
                                                        v[i].rate = val.rate;
                                                        v[i].gst = val.gst;
                                                        v[i].mrp = val.mrp;
                                                        return [...v];
                                                    });
                                                }}
                                            >
                                                {data.wProdName}
                                            </SearchProductTd>
                                            <td className='text-end' >{data.qty}</td>
                                            <td className='text-end' >{data.rate}</td>
                                            <td className='text-end' >{data.mrp}</td>
                                            <td className='text-end' >{data.gst}</td>
                                            <td
                                            >
                                                <div
                                                    className={
                                                        ` d-flex ${data.wProdName ? "justify-content-between" : "justify-content-end"} `

                                                    }>
                                                    {data.wProdName &&


                                                        <Popconfirm
                                                            title="Remove Wholesale Product"
                                                            description="Are you sure to remove Mapping wholesale product?"
                                                            onConfirm={() => {
                                                                removeWholesaleProduct(data)
                                                            }}
                                                            // onCancel={ }
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <FontAwesomeIcon
                                                                className='ml-2'
                                                                icon={faEraser}
                                                                role='button'
                                                                onClick={e => {

                                                                }}
                                                                title='Erase/Remove Wholesale Product'
                                                            />
                                                        </Popconfirm>
                                                    }

                                                    <Popconfirm
                                                        title="Delete Row"
                                                        description="Are you sure to Delete Row?"
                                                        onConfirm={() => {
                                                            setFinalData(v => {
                                                                v = v.filter(c => c.rProdName !== data.rProdName);

                                                                return [...v];
                                                            })


                                                        }}
                                                        // onCancel={ }
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <FontAwesomeIcon
                                                            className='ml-2'
                                                            icon={faTrash}
                                                            role='button'
                                                            onClick={e => {

                                                            }}
                                                            title='Delete Row'
                                                        />
                                                    </Popconfirm>

                                                </div>
                                            </td>
                                        </tr>
                                    })

                                        :
                                        <tr className='bg-white'
                                        >
                                            <td colSpan={7}
                                                className='   text-center p-3'
                                            >
                                                No Data Found
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        </Table>

                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='mb-2 '>
                                <label htmlFor='urgentSelect'>Delivery Type</label>
                                <select id='urgentSelect'
                                    className='form-control  '
                                    value={urgent} onChange={e => {
                                        setUrgent(e.target.value);
                                    }}>
                                    <option value={0}>Normal</option>
                                    <option value={1}>Urgent</option>
                                </select>
                            </div>
                            <label>Remarks</label>
                            <br />
                            <div className="dropdowns w-100">
                                <span className="dropdwnsName" >{remarksDropdown.displayName}</span>
                                <div className="dropdown-content"
                                    onBlur={() => {

                                    }}
                                >
                                    <li
                                        role='button'
                                        name="PickUpOrder"
                                        onClick={() => setRemarksDropdown(v => {
                                            return { ...v, displayName: v.pickupOrder }
                                        })}
                                        value="PickupOrder"
                                        style={{ color: "rgb(89, 91, 91)" }}
                                    >{remarksDropdown.pickupOrder}</li>
                                    <li
                                        role='button'
                                        onClick={() => setRemarksDropdown(v => {
                                            return { ...v, displayName: v.deliveryOrder }
                                        })}
                                        value="Delivery Order"
                                        style={{ color: "rgb(89, 91, 91)" }}
                                    >{remarksDropdown.deliveryOrder}</li>
                                    <li
                                        role='button'
                                        onClick={() => {

                                            remarksDropdown.customValue && setRemarksDropdown(v => {
                                                return { ...v, displayName: v.customValue }
                                            })
                                        }
                                        }
                                        value="Remarks"
                                        style={{ fontWeight: "bold" }}
                                    >Remarks

                                        <textarea name="Text1" cols="68" rows="2"
                                            value={remarksDropdown.customValue}
                                            onChange={e => {
                                                setRemarksDropdown(v => {
                                                    return { ...v, customValue: e.target.value }
                                                })
                                            }}
                                            onBlur={() => {
                                                remarksDropdown.customValue && setRemarksDropdown(v => {
                                                    return { ...v, displayName: v.customValue }
                                                })
                                            }}
                                        ></textarea></li>
                                </div>
                            </div>

                            <br />
                            <br />
                            Approx.: {totalVal}

                            <br />
                            <br />
                            <button className='btn btn-success'
                                style={{ borderRadius: 0 }}
                                onClick={writePopsOrderHandler}
                                disabled={disableSubmitBtn}
                            >
                                {disableSubmitBtn && <div className="spinner-border spinner-border-sm text-light" role="status">
                                    <span className="sr-only"> </span>
                                </div>}
                                &nbsp;
                                Submit</button>

                            <button
                                style={{ borderRadius: 0, marginLeft: 10 }}
                                className='btn  btn-secondary  text-right '

                                onClick={e => {
                                    clearFull();
                                }}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </div>


            <Modal
                show={showSuccessModal}
                onHide={() => {
                    setOrderNo('');
                    setShowSuccessModal(false)
                }}
            >
                <div
                    className='d-flex justify-content-center pt-3 '
                >

                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        style={{}}
                        width="100" height="100" viewBox="0 0 48 48">

                        <path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>

                    </svg>
                </div>
                <h5 style={{ textAlign: 'center', color: " rgb(83, 82, 82)", paddingBottom: "10px", paddingTop: "20px" }}>Your order was successfully submitted!</h5>
                <h6
                    className=" text-center mb-4"
                >
                    Successfully Saved with order number {orderNo}
                </h6>


                <Button type='primary'
                    style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        // backgroundColor: "green"
                    }}
                    onClick={e => {
                        setOrderNo('');
                        setShowSuccessModal(false)
                    }}
                >Close</Button>

            </Modal>


            <Footer></Footer>
        </div>
    )
}

export default OrderUpload;