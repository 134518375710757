import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import {
    AsyncTypeahead,
    Hint,
    Menu,
    MenuItem,
} from "react-bootstrap-typeahead";
import PropsType from "prop-types";
import { Empty } from "antd";
// import './typeahead.css';

const Typeahead = forwardRef((propsFull, ref) => {
    const filterBy = () => true;
    // console.log("DDD");
    const {
        tabIndex,
        colWidth,
        isMobile,
        columns,
        labelKey,
        hasError,
        className,
        optionHeight,
        removeBorder,
        ...props
    } = propsFull;
    const sizeClass = props.size === "sm" ? " form-control-xs  " : " form-control-sm ";
    return (
        <AsyncTypeahead
            {...props}
            ref={ref}
            // clearButton
            delay={0}
            flip={true}
            // positionFixed={true}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                return (
                    <Hint
                        shouldSelect={(shouldSelect, e) => {
                            // Select the hint if the user hits 'enter' or ','
                            return e.keyCode === 13 || shouldSelect;
                        }}
                    >

                        <Form.Control
                            {...inputProps}
                            autoComplete="none"
                            tabIndex={tabIndex}
                            style={{
                                ...inputProps.style,
                            }}
                            className={inputProps.className + "   " + sizeClass + (removeBorder ? " border-0 shadow-none " : "")}
                            ref={(element) => {
                                inputRef(element);
                                referenceElementRef(element);
                            }}
                        />
                    </Hint>
                );
            }}
            renderMenu={(results, menuPropsFull) => {
                // console.log({ results, menuPropsFull });
                const { newSelectionPrefix,
                    paginationText,
                    renderMenuItemChildren,
                    ...menuProps } = menuPropsFull;
                if (!columns || columns.length === 0) {
                    return (
                        <Menu {...menuProps}
                        >
                            {results.map((result, index) => (
                                <MenuItem key={index} option={result} position={index}>
                                    {props.name ? result[props.name] : result.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    );
                }
                // console.log("menuProps ", menuProps);
                if (results.length === 0) {
                    return <></>
                }
                return (
                    <Menu
                        {...menuProps}
                        style={{
                            ...menuProps.style,
                            width:
                                results.length > 0 ? (colWidth ? colWidth : `700px`) : "auto",

                            // height: props.optionHeight ? props.optionHeight : menuProps.style.height
                        }}
                    >
                        {results.length > 0 ? (
                            <>
                                {!isMobile ? (
                                    <>
                                        <div
                                            className="row mx-2 "
                                            style={{ overflowY: "scroll", scrollbarWidth: "12px" }}
                                        >
                                            {columns.map((data, i) => (
                                                <InputForWidth
                                                    key={i}
                                                    value={data.header}
                                                    header={true}
                                                    cols={data.cols}
                                                />
                                            ))}
                                        </div>
                                        <div
                                            style={{
                                                overflowY: "scroll",
                                                overflowX: "hidden",
                                                height: "200px",
                                                // height: optionHeight ? optionHeight : "200px"

                                            }}
                                        >
                                            {results.map((result, index) => (
                                                <MenuItem
                                                    className="border-bottom"
                                                    option={result}
                                                    key={index}
                                                    position={index}
                                                >
                                                    <div className="row m-0 p-0">
                                                        {columns.map((data, i) => (
                                                            <InputForWidth
                                                                key={i}
                                                                value={result[data.field]}
                                                                cols={data.cols}
                                                            />
                                                        ))}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {results.map((result, index) => (
                                            <MenuItem
                                                className="border-bottom"
                                                option={result}
                                                key={index}
                                                position={index}
                                            >
                                                {columns.map((data, i) => (
                                                    <React.Fragment key={i}>
                                                        <div
                                                            className={
                                                                "row" + (i === 0 ? " border-bottom mb-2 " : "")
                                                            }
                                                            style={{ width: "100%", paddingLeft: 5 }}
                                                        >
                                                            <div
                                                                className={"col-5"}
                                                                style={{ fontWeight: i === 0 ? 500 : 400 }}
                                                            >
                                                                {data.header}
                                                            </div>
                                                            <div className="col-7">{result[data.field]}</div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </MenuItem>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <Empty />
                        )}
                    </Menu>
                );
            }}
            filterBy={filterBy}
            minLength={2}
            labelKey={labelKey ? labelKey : "name"}
            inputProps={{
                autoComplete: "none",

                className:
                    "  " + (hasError ? "is-invalid" : "") + "  " + className
                        ? className
                        : "",
            }}
            multiple={false}
            autocomplete="none"
            useCache={false}
        />
    );
});

// https://reactjs.org/docs/typechecking-with-proptypes.html
Typeahead.propTypes = {
    name: PropsType.string,
    placeholder: PropsType.string,
    colWidth: PropsType.oneOfType([PropsType.string, PropsType.number]),
    isMobile: PropsType.bool,
    tabIndex: PropsType.number,
    id: PropsType.string,
    columns: PropsType.array,
    isLoading: PropsType.bool,
    options: PropsType.array,
    selected: PropsType.array,
    onSearch: PropsType.func,
    onChange: PropsType.func,
    labelKey: PropsType.string,
    optionHeight: PropsType.oneOfType([PropsType.string, PropsType.number]),
    removeBorder: PropsType.bool,
    size: PropsType.string,
    dropup: PropsType.bool
};

export default Typeahead;



const InputForWidth = (props) =>
    <div
        className={
            `col-${props.cols ? props.cols : 1}  border-right  p-0 pr-1 pl-2 ` +
            (props.header ? " border-bottom " : " ")
        }
    >
        <div
            className={" bg-transparent border-0   "}
            style={{ fontSize: "12px", fontWeight: props.header ? "500" : "400" }}
        >
            {props.value ? props.value : ""}
        </div>
    </div>
