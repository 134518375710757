const { createSlice } = require('@reduxjs/toolkit');

// const initialState = [];

const CustOrder = createSlice({
    name: 'order',
    bool: 'stockCust',
    initialState: {
        showtostockcst: null,
        custcodes: null,
        cName: '',
        cplace: '',
        // customers: [
        //     {
        //         id: 1,
        //         name: "rane",
        //         product: [{ id: 1, name: "sabar" }]
        //     },
        //     {
        //         name: "ranveer",
        //         product: [{ id: 1, name: "noodles" }]
        //     }
        // ]
    },

    reducers: {
        add(state, { payload }) {
            state.custcodes = payload;
            // state.cName = payload;
            // state.place = payload;
        },

        addcstName(state, { payload }) {
            state.cName = payload;
        },
        addcstPlace(state, { payload }) {
            state.cplace = payload;
        },
        // add(state, action) {
        //     state.push(action.payload);
        // },
        // add(state, action) {
        //     state.push(action.payload);
        // },
        // addCustomer(state, { payload }) {
        //     state.customers.push(payload);
        // },
        // addProducts(state, { payload }) {
        //     const index = state.customers.findIndex(c => c.id === payload.id)
        //     if (index >= 0) {
        //         state.customers[index].product.push(payload.product)
        //     }
        // },
        // {id:1:product:{in:1}}

        boolstock(state, { payload }) {
            state.showtostockcst = payload;
        },
        remove(state, action) {
            return state.filter((item) => item.id !== action.payload);
        },
    },

});

export const { add, addcstName, addcstPlace, remove, boolstock } = CustOrder.actions;
export default CustOrder.reducer;
