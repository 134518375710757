const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    searchProduct: [],
    selecteddata: [],
    remarksMessageBYcomp: "",
    increaseprice: 0,
};

const UserOrder = createSlice({
    name: 'userOrders',
    initialState: initialState,
    reducers: {
        add(state, action) {
            state.push(action.payload);
        },
        setProductData(state, { payload }) {
            state.searchProduct = payload;
        },
        setselecteddata(state, { payload }) {
            state.selecteddata = payload;
        },
        // setQty(state, { payload }) {
        //     console.log("aaaaa  ", state.selecteddata[payload.index]);
        //     if (state.selecteddata[payload.index]) {
        //         state.selecteddata[payload.index].qty = payload.value
        //     }
        // },
        setQty(state, { payload }) {
            console.log("ccccc  ", state.searchProduct[payload.index]);
            if (state.searchProduct[payload.index]) {
                state.searchProduct[payload.index].qty = payload.value

            }
        },
        // setQty(state, { payload }) {
        //     console.log("ccccc  ", state.selecteddata[payload.index]);
        //     if (state.selecteddata[payload.index]) {
        //         state.selecteddata[payload.index].qty = payload.value
        //     }
        // },
        remarkMessagebycomp(state, { payload }) {
            state.remarksMessageBYcomp = payload;
        },

        totalprice(state, { payload }) {
            state.increaseprice = payload;
        },
        arraylist(state, { payload }) {
            state.selecteddata = payload;
        },
        // removeItem: (state, action) => {
        //     console.log(action);
        //     const itemID = action.payload;
        //     const temp = state.searchProduct.filter((item) => item.productCode !== itemID);
        //     state.searchProduct = temp;
        // },
        removeItem(state, { payload }) {
            console.log("deleted Data", payload);
            // state.pop(action.payload)
            //  state.splice(action.payload, 1)
            const deletestate = JSON.parse(localStorage.getItem("selcted-Products"))
            const setRTolocal = deletestate.filter((val, i) => {
                return val.productCode !== payload.productCode
            })
            // state.searchProduct = setRTolocal;
            localStorage.setItem("selcted-Products", JSON.stringify(setRTolocal));
            // window.location.reload()
            // const itemID = action.payload;
            //state.searchProduct= state.searchProduct.filter((item, index) => index !== itemID)

        }
        // removeData: (state, action) => {
        //     const itemID = action.payload;
        //     state.selecteddata = state.selecteddata.filter((item) => item.prodCode !== itemID);
        // }

        // arraylist(state, { payload }) {
        //     state.searchProduct = payload;
        // },items: state.searchProduct.filter((item, index) => index !== action.payload)

    },

});

export const { add, totalprice, remarkMessagebycomp, setNoOfQuantity, arraylist, setQty, removeItem, removeData } = UserOrder.actions;
export const userOrderAction = UserOrder.actions;
export default UserOrder.reducer;
